import axios from 'axios';
import CONFIG from 'config';
import { getAccessTokenFromCookie } from 'utils/auth/auth';

//TODO: Input params needs clean-up since few of those are not used anymore
export const getSlides = async (doc, searchTerm, aiDocs, imgRes) => {
  if (doc && !doc.slides) {
    const kpCmsId = doc.kpCmsId;
    const docUrl = doc.url;

    if (!doc.previews || !docUrl || !kpCmsId) {
      console.warn('Missing preview data or URL and DocViz wont open');
      return doc;
    }

    const mergedSlideData = doc.previews.map(p => {
      return {
        slideNumber: p.pageNumber,
        htmlUrl: p.html?.hqUrl ? `${docUrl}/${doc.imagePath}/${p.html.hqUrl}` : '',
        imageRef: p[imgRes]?.hqUrl ? `${docUrl}/${doc.imagePath}/${p[imgRes].hqUrl}` : '',
      };
    });
    
    const relevantSlides = []; //relevanceSlides field is missing from genai-enbl-retrieval api response
    return {
      ...doc,
      previewAvailable: doc.hasHtmlPreview,
      entitled: doc.openAccessInd,
      slides: mergedSlideData,
      relevantSlides,
      hasSlides: true,
      kpId: kpCmsId,
      searchAbstract: doc.searchAbstract || doc.materialDesc,
      previews: doc.previews,
      s3Object: doc.s3Object,
    };
  } else {
    return doc;
  }
};



export const getApiEnvironment = () => {
  const envArr = CONFIG.SERVER_URL.split('.');
  return envArr.length <= 3 ?
    envArr[0] :
    envArr.slice(0, 2).join('.');
};

export const getApiConfig = () => ({
  key: CONFIG.X_API_KEY,
  token: getAccessTokenFromCookie,
  env: getApiEnvironment()
});

export function getSlideNumbers(selectedSlides) {
  const slideNumbers = [];
  selectedSlides.forEach((slide) => slideNumbers.push(Number(slide.slideNumber)));
  return slideNumbers;
}

export const genaiSlideSearch = async (query, document) => {
  let kpCmsId = document.kpCmsId;
  const request_body = { query: query, guid: [kpCmsId], resultsPerPage: 100000, matchMode: 'exact' };
  const retrievalData = await axios.post(CONFIG.API_URL.SLIDE_LEVEL_SEARCH, request_body);
  const augmentedData = {
    'resultCount': retrievalData.resultCount,
    'attachments': retrievalData.attachments[0]
  };
  return augmentedData;
};